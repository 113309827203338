import { useState } from "react"
import "./Header.css"
import Bars from "../../../assets/bars.png"
import Logo from "../../../assets/logo.png"
import { Link } from "react-scroll"
function Header() {
    const mobile = window.innerWidth <= 768 ? true : false
    const [menuOpened, setMenuOpened] = useState(false);
    return (
        <div className="header">
            <img className='logo' src={Logo} alt="logo" />
            {(menuOpened === false && mobile === true) ? (
                <div
                    style={{
                        backgroundColor: "var(--appColor)",
                        padding: "0.5rem",
                        borderRadius: "5px",
                    }}
                    onClick={() => setMenuOpened(true)}
                >
                    <img src={Bars} alt="bars"
                        style={{
                            width: "1.5rem",
                            height: "1.5rem"
                        }}
                    />
                </div>
            ) :
                <ul className="header-menu">
                    <li>
                        <Link
                            onClick={() => setMenuOpened(false)}
                            activeclassname="active"
                            to="home"
                            spy={true}
                            smooth={true}> Home</Link></li>
                    <li>
                        <Link onClick={() => setMenuOpened(false)}
                            to="programme"
                            spy={true}
                            smooth={true}>Programs</Link>
                    </li>
                    <li>
                        <Link onClick={() => setMenuOpened(false)}
                            to="reasons"
                            spy={true}
                            smooth={true}>Why us</Link>
                    </li>
                    <li >
                        <Link onClick={() => setMenuOpened(false)} to="plan"
                            spy={true}
                            smooth={true}>Plans</Link></li>
                    <li >
                        <Link
                            onClick={() => setMenuOpened(false)}
                            to="testimonials"
                            spy={true}
                            smooth={true}>Testimonials</Link>
                    </li>
                </ul>}

        </div>
    )
}

export default Header